import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui'
import 'default-passive-events'
import dataV from '@jiaminghi/data-view'
//样式文件
// 引入elementui样式
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/reset.less'
import './assets/icon/iconfont.css'
// main.js
import 'lib-flexible'
//引入百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(ElementUI)
Vue.use(dataV)
Vue.use(BaiduMap,{
  ak:'AIsyUmSRbWMKnHfYHFdWxQXSjj6We9m6'
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
