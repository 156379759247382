import constantRoutes from '../../router/routerConfig'
// 状态
const state = {
    token:'',
    user:{
        name:'' // 名称
    },
    staffRole: [], // 菜单
    activePath:'',
    activeMenu: 0,   // 头部菜单

}
// 修改state状态必须通过mutations对象
const mutations = {
    // SETLOGINUSERINFO(state,data) {
    //     const {token} = data
    //     state.token = token
       
    //     state.staffRole = constantRoutes[1].children
    // },
    // SETACTIVEPATH(state, data){
    //     state.activePath = data
    // },
    // SETUSERINFO(state,data){
    //     state.user.orgaPathIds = data.orgaPathIds
    //     let len = data.orgaPathIds.split('-')
    //     state.user.isProject = len.length > 2
    // },
    // CHANGETOPMENU(state, data){
    //     const allMenu = constantRoutes[1].children
    //     state.activeMenu = data
    //     state.staffRole = allMenu[data].children
    //     console.log('state.staffRole', state.staffRole)
    // }
    SETLOGINUSERINFO(state,data) {
        let {token,orgaId,projId,staffId,staffName,staffSex,staffStatus,staffType,staffRole,orgaLevel,compAlias,compIcon} = data
        state.token = token
        if(staffRole && staffRole.length > 0){
            // 处理左侧菜单的展示规则
            let staffRoleLIst = staffRole.sort((a,b)=>a.moduSort - b.moduSort).map(item =>{
                let get = (data) =>{
                    return  data.sort((a,b)=>a.moduSort - b.moduSort).map(child =>{
                        return {
                            meta:{
                                title: child.moduName,
                                icon: child.moduIcon || 'el-icon-s-tools'
                            },
                            path: child.moduUrl,
                            fullPath: child.moduUrl,
                            children: child.children && child.children.length >0 ? get(child.children): null
                        }
                    })
                }
                return {
                    meta:{
                        title: item.moduName,
                        icon: item.moduIcon || 'el-icon-s-tools'
                    },
                    path: item.moduUrl,
                    fullPath: item.moduUrl,
                    children: get(item.children)
                }
            })
            state.staffRole = staffRoleLIst
        }else {
            state.staffRole = []
        }
        // 人员信息
        state.user = {orgaId,projId,staffId,staffName,staffSex,staffStatus,staffType,orgaLevel,compAlias,compIcon}
    },
    SETACTIVEPATH(state, data){
        state.activePath = data
    },
    SETUSERINFO(state,data){
        state.user.orgaPathIds = data.orgaPathIds
        let len = data.orgaPathIds.split('-')
        state.user.isProject = len.length > 2
    }

}
const actions ={
    setLoginUserInfo(context,param){
        context.commit('SETLOGINUSERINFO', param)
    },
    setActivePath(context,param){
        let path = param.split('?')[0]
        context.commit('SETACTIVEPATH', path)
    },
    setUserInfo(context,param){
        context.commit('SETUSERINFO', param)
    },
    changeTopMenu(context, param){
        context.commit('CHANGETOPMENU', param)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}