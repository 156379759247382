
import Vue from 'vue'
import Vuex from 'vuex'
// 将vuex存入localstorage 中，解决vuex刷新后无数据 参考：https://www.cnblogs.com/xiaoyaoxingchen/p/16202848.html
import createPersistedState from "vuex-persistedstate"
//模块
import common from "./modules/common";
import tab from "./modules/tab";
import rights from "./modules/rights";
Vue.use(Vuex)

const store = new Vuex.Store({
    // 状态
    state: {},
    // 计算属性，修改state状态，只能写同步方法
    mutations: {},
    // 异步动作方法
    actions: {},
    // 模块
    modules: {
        common,
        tab,
        rights
    },
    plugins: [createPersistedState({ storage: window.sessionStorage })],
})

// 导出这些属性，供外部可调用，相当于是个准许证
export default store