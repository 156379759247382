const constantRoutes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/view',
        name: 'view',
        redirect: '/view/monitor',
        component: () => import('@/view/layout/index'),
        children: [
            {
                path: 'monitor',
                name: 'monitor',
                fullPath: '/view/monitor',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '监控中心',
                    icon: 'icon-jiankongzhongxin'
                },
                children: [
                    // {
                    //     path: 'controlCenter',
                    //     name: 'controlCenter',
                    //     fullPath: '/view/monitor/controlCenter',
                    //     component: () => import('@/view/pages/monitor/controlCenter'),
                    //     meta: {
                    //         title: '控制中心',
                    //         icon: 'el-icon-s-tools'
                    //     },
                    // },
                    {
                        path: 'electronicMap',
                        name: 'electronicMap',
                        fullPath: '/view/monitor/electronicMap',
                        component: () => import('@/view/pages/monitor/electronicMap'),
                        meta: {
                            title: '电子地图',
                            icon: 'icon-dianziditu'
                        },
                    },
                    {
                        path: 'deviceTotal',
                        name: 'deviceTotal',
                        fullPath: '/view/monitor/deviceTotal',
                        component: () => import('@/view/pages/monitor/deviceTotal'),
                        meta: {
                            title: '设备统计',
                            icon: 'icon-shebeitongji'
                        },
                    },
                    {
                        path: 'deviceSelect',
                        name: 'deviceSelect',
                        fullPath: '/view/monitor/deviceSelect',
                        component: () => import('@/view/pages/monitor/deviceSelect'),
                        meta: {
                            title: '设备查询',
                            icon: 'icon-gongzuoguanli'
                        },
                    },
                    {
                        path: 'realMonitor',
                        name: 'realMonitor',
                        fullPath: '/view/monitor/realMonitor',
                        component: () => import('@/view/pages/monitor/realMonitor'),
                        meta: {
                            title: '实时监控',
                            icon: 'icon-shishijiankong'
                        },
                    },
                    {
                        path: 'equmentTotal',
                        name: 'equmentTotal',
                        fullPath: '/view/monitor/equmentTotal',
                        component: () => import('@/view/pages/monitor/equmentTotal'),
                        meta: {
                            title: '设备统计',
                          
                        },
                    },
                    {
                        path: 'deviceTotalProject',
                        name: 'deviceTotalProject',
                        fullPath: '/view/monitor/deviceTotalProject',
                        component: () => import('@/view/pages/monitor/deviceTotalProject'),
                        meta: {
                            title: '项目统计',
                          
                        },
                    },
                    {
                        path: 'candidCamera',
                        name: 'candidCamera',
                        fullPath: '/view/monitor/candidCamera',
                        component: () => import('@/view/pages/monitor/candidCamera'),
                        meta: {
                            title: '远程抓拍',
                            icon: 'icon-yuanchengzhuapai'
                        },
                    },
                    {
                        path: 'alarm',
                        name: 'alarm',
                        fullPath: '/view/monitor/alarm',
                        component: () => import('@/view/pages/monitor/alarm'),
                        meta: {
                            title: '报警排行',
                            icon: 'icon-baojingliebiao'
                        },
                    },
                    {
                        path: 'alarmDel',
                        name: 'alarmDel',
                        fullPath: '/view/monitor/alarmDel',
                        component: () => import('@/view/pages/monitor/alarmDel'),
                        meta: {
                            title: '报警排行详情',
                            
                        },
                    },
                    {
                        path: 'illegal',
                        name: 'illegal',
                        fullPath: '/view/monitor/illegal',
                        component: () => import('@/view/pages/monitor/illegal'),
                        meta: {
                            title: '违章排行',
                            icon: 'icon-yibanweifahangwei'
                        },
                    },
                    {
                        path: 'illegalDel',
                        name: 'illegalDel',
                        fullPath: '/view/monitor/illegalDel',
                        component: () => import('@/view/pages/monitor/illegalDel'),
                        meta: {
                            title: '违章排行详情',
                           
                        },
                    },
                    {
                        path: 'work',
                        name: 'work',
                        fullPath: '/view/monitor/work',
                        component: () => import('@/view/pages/monitor/work'),
                        meta: {
                            title: '工作循环',
                            icon: 'icon-gongzuoxunhuan'
                        },
                    },
                    {
                        path: 'deviceRun',
                        name: 'deviceRun',
                        fullPath: '/view/monitor/deviceRun',
                        component: () => import('@/view/pages/monitor/deviceRun'),
                        meta: {
                            title: '设备运行',
                            icon: 'icon-shebeiyunhang'
                        },
                    },
                    {
                        path: 'towerOperator',
                        name: 'towerOperator',
                        fullPath: '/view/monitor/towerOperator',
                        component: () => import('@/view/pages/monitor/towerOperator'),
                        meta: {
                            title: '塔机司机',
                            icon: 'icon-tajisiji'
                        },
                    },
                    {
                        path: 'towerOperatorDel',
                        name: 'towerOperatorDel',
                        fullPath: '/view/monitor/towerOperatorDel',
                        component: () => import('@/view/pages/monitor/towerOperatorDel'),
                        meta: {
                            title: '塔机司机详情',
                            
                        },
                    },
                    {
                        path: 'fugitiveRank',
                        name: 'fugitiveRank',
                        fullPath: '/view/monitor/fugitiveRank',
                        component: () => import('@/view/pages/monitor/fugitiveRank'),
                        meta: {
                            title: '扬尘排行',
                            
                        },
                    },
                    {
                        path: 'cameraSelect',
                        name: 'cameraSelect',
                        fullPath: '/view/monitor/cameraSelect',
                        component: () => import('@/view/pages/monitor/cameraSelect'),
                        meta: {
                            title: '视频查询',
                            
                        },
                    },
                    {
                        path: 'realDevice',
                        name: 'realDevice',
                        fullPath: '/view/monitor/realDevice',
                        component: () => import('@/view/pages/monitor/realDevice'),
                        meta: {
                            title: '项目监控',
                            
                        },
                    },
                ]
            },
            {
                path: 'basic',
                name: 'basic',
                fullPath: '/view/basic',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '基础管理',
                    icon: 'icon-jichuguanli'
                },
                children: [
                    {
                        path: 'unit',
                        name: 'unit',
                        fullPath: '/view/basic/unit',
                        component: () => import('@/view/pages/basic/unit'),
                        meta: {
                            title: '单位管理',
                            icon: 'icon-danweiguanli'
                        },
                    },
                    {
                        path: 'customer',
                        name: 'customer',
                        fullPath: '/view/basic/customer',
                        component: () => import('@/view/pages/basic/customer'),
                        meta: {
                            title: '商户管理',
                            icon: 'icon-shanghuguanli'
                        },
                    },
                    {
                        path: 'record',
                        name: 'record',
                        fullPath: '/view/basic/record',
                        component: () => import('@/view/pages/basic/record'),
                        meta: {
                            title: '设备档案',
                            icon: 'icon-shebeidangan'
                        },
                    },
                    {
                        path: 'project',
                        name: 'project',
                        fullPath: '/view/basic/project',
                        component: () => import('@/view/pages/basic/project'),
                        meta: {
                            title: '项目信息',
                            icon: 'icon-xiangmuxinxi'
                        },
                    },
                    {
                        path: 'driver',
                        name: 'driver',
                        fullPath: '/view/basic/driver',
                        component: () => import('@/view/pages/basic/driver'),
                        meta: {
                            title: '驾驶人员',
                            icon: 'icon-zhongdianjiashirenyuanjibenxinxi'
                        },
                    },
                    {
                        path: 'staffBinding',
                        name: 'staffBinding',
                        fullPath: '/view/basic/staffBinding',
                        component: () => import('@/view/pages/basic/staffBinding'),
                        meta: {
                            title: '人员绑定',
                            icon: 'icon-renyuanbangdingguanliPersonnel-binding-management'
                        },
                    },
                    {
                        path: 'termiParam',
                        name: 'termiParam',
                        fullPath: '/view/basic/termiParam',
                        component: () => import('@/view/pages/basic/termiParam'),
                        meta: {
                            title: '终端参数',
                            icon: 'icon-zhongduancanshu'
                        },
                    },
                    {
                        path: 'deviceON',
                        name: 'deviceON',
                        fullPath: '/view/basic/deviceON',
                        component: () => import('@/view/pages/basic/deviceON'),
                        meta: {
                            title: '设备启用',
                            icon: 'icon-shebeiqiyong'
                        },
                    },
                    {
                        path: 'deviceDismant',
                        name: 'deviceDismant',
                        fullPath: '/view/basic/deviceDismant',
                        component: () => import('@/view/pages/basic/deviceDismant'),
                        meta: {
                            title: '设备拆卸',
                            icon: 'icon-shebeichaixie'
                        },
                    },
                    {
                        path: 'announcement',
                        name: 'announcement',
                        fullPath: '/view/basic/announcement',
                        component: () => import('@/view/pages/basic/announcement'),
                        meta: {
                            title: '通知公告',
                            icon: 'icon-tongzhigonggao'
                        },
                    }
                ]
            },
            
            // {
            //     path: 'filings',
            //     name: 'filings',
            //     fullPath: '/view/filings',
            //     component: () => import('@/view/layout/layoutView'),
            //     meta: {
            //         title: '备案管理',
            //         icon: 'icon-caozuo-caidan'
            //     },
            //     children: [
            //         {
            //             path: 'companyFilings',
            //             name: 'companyFilings',
            //             fullPath: '/view/filings/companyFilings',
            //             component: () => import('@/view/pages/filings/companyFilings'),
            //             meta: {
            //                 title: '企业备案',
            //                 icon: 'el-icon-s-tools'
            //             },
            //         }
            //     ]
            // },
            {
                path: 'data',
                name: 'data',
                fullPath: '/view/data',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '数据中心',
                    icon: 'icon-shujuzhongxin'
                },
                children: [
                    {
                        path: 'hoisting',
                        name: 'hoisting',
                        fullPath: '/view/data/hoisting',
                        component: () => import('@/view/pages/data/hoisting'),
                        meta: {
                            title: '起重设备统计',
                            icon: 'icon-type-produce-o'
                        },
                    },
                    {
                        path: 'orgProject',
                        name: 'orgProject',
                        fullPath: '/view/data/orgProject',
                        component: () => import('@/view/pages/data/orgProject'),
                        meta: {
                            title: '组织项目报告',
                            icon: 'icon-dingdanchuli'
                        },
                    },
                    {
                        path: 'proDevice',
                        name: 'proDevice',
                        fullPath: '/view/data/proDevice',
                        component: () => import('@/view/pages/data/proDevice'),
                        meta: {
                            title: '项目设备报告',
                            icon: 'icon-xiangmukadingdanxiaoshoutongjipc'
                        },
                    },
                    {
                        path: 'staffLogin',
                        name: 'staffLogin',
                        fullPath: '/view/data/staffLogin',
                        component: () => import('@/view/pages/data/staffLogin'),
                        meta: {
                            title: '人员登录查询',
                            icon: 'icon-customer'
                        },
                    },
                    {
                        path: 'face',
                        name: 'face',
                        fullPath: '/view/data/face',
                        component: () => import('@/view/pages/data/face'),
                        meta: {
                            title: '人脸识别记录',
                            icon: 'icon-ziyuan236'
                        },
                    },
                    {
                        path: 'termiAlarm',
                        name: 'termiAlarm',
                        fullPath: '/view/data/termiAlarm',
                        component: () => import('@/view/pages/data/termiAlarm'),
                        meta: {
                            title: '终端告警明细',
                            icon: 'icon-zhongduanbeian'
                        },
                    },
                    {
                        path: 'terAlaTotal',
                        name: 'terAlaTotal',
                        fullPath: '/view/data/terAlaTotal',
                        component: () => import('@/view/pages/data/terAlaTotal'),
                        meta: {
                            title: '终端报警统计',
                            icon: 'icon-fengxianyuanxinxitongji'
                        },
                    },
                    {
                        path: 'lease',
                        name: 'lease',
                        fullPath: '/view/data/lease',
                        component: () => import('@/view/pages/data/lease'),
                        meta: {
                            title: '租赁单位评估',
                            icon: 'icon-dingdanchuli'
                        },
                    },
                    {
                        path: 'leaseDel',
                        name: 'leaseDel',
                        fullPath: '/view/data/leaseDel',
                        component: () => import('@/view/pages/data/leaseDel'),
                        meta: {
                            title: '租赁单位评估详情',
                           
                        },
                    },
                    {
                        path: 'projectTotal',
                        name: 'projectTotal',
                        fullPath: '/view/data/projectTotal',
                        component: () => import('@/view/pages/data/projectTotal'),
                        meta: {
                            title: '工程文件汇总',
                            icon: 'icon-gongzuoguanli'
                        },
                    },
                    
                ]
            },{
                path: 'sysManage',
                name: 'sysManage',
                fullPath: '/view/sysManage',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '系统管理',
                    icon: 'icon-xitongguanli'
                },
                children: [
                   
                    {
                        path: 'modu',
                        name: 'modu',
                        fullPath: '/view/sysManage/modu',
                        component: () => import('@/view/pages/sysManage/modu'),
                        meta: {
                            title: '模块管理',
                            icon: 'icon-mokuaiguanli'
                        },
                    },
                    {
                        path: 'role',
                        name: 'role',
                        fullPath: '/view/sysManage/role',
                        component: () => import('@/view/pages/sysManage/role'),
                        meta: {
                            title: '角色管理',
                            icon: 'icon-jiaoseguanli'
                        },
                    },
                    {
                        path: 'rolePermission',
                        name: 'rolePermission',
                        fullPath: '/view/sysManage/rolePermission',
                        component: () => import('@/view/pages/sysManage/rolePermission'),
                        meta: {
                            title: '角色权限',
                            icon: 'icon-role'
                        },
                    },
                    {
                        path: 'staffRole',
                        name: 'staffRole',
                        fullPath: '/view/sysManage/staffRole',
                        component: () => import('@/view/pages/sysManage/staffRole'),
                        meta: {
                            title: '用户角色',
                            icon: 'icon-yonghujiaose'
                        },
                    },
                    {
                        path: 'notice',
                        name: 'notice',
                        fullPath: '/view/sysManage/notice',
                        component: () => import('@/view/pages/sysManage/notice'),
                        meta: {
                            title: '通知设置',
                            icon: 'icon-tongzhishezhi'
                        },
                    },
                    {
                        path: 'organization',
                        name: 'organization',
                        fullPath: '/view/sysManage/organization',
                        component: () => import('@/view/pages/sysManage/organization'),
                        meta: {
                            title: '组织机构',
                            icon: 'icon-zuzhijigou'
                        },
                    },
                    {
                        path: 'staff',
                        name: 'staff',
                        fullPath: '/view/sysManage/staff',
                        component: () => import('@/view/pages/sysManage/staff'),
                        meta: {
                            title: '人员管理',
                            icon: 'icon-renyuanguanli'
                        },
                    },
                    {
                        path: 'version',
                        name: 'version',
                        fullPath: '/view/sysManage/version',
                        component: () => import('@/view/pages/sysManage/version'),
                        meta: {
                            title: '版本发布',
                            icon: 'icon-banbenfabu'
                        },
                    },
                    {
                        path: 'batchUpgrade',
                        name: 'batchUpgrade',
                        fullPath: '/view/sysManage/batchUpgrade',
                        component: () => import('@/view/pages/sysManage/batchUpgrade'),
                        meta: {
                            title: '批量升级',
                            icon: 'icon-piliangshengji_huaban'
                        },
                    },
                    {
                        path: 'deviceTermi',
                        name: 'deviceTermi',
                        fullPath: '/view/sysManage/deviceTermi',
                        component: () => import('@/view/pages/sysManage/deviceTermi'),
                        meta: {
                            title: '终端备案',
                            icon: 'icon-zhongduanbeian'
                        },
                    },
                    {
                        path: 'video',
                        name: 'video',
                        fullPath: '/view/sysManage/video',
                        component: () => import('@/view/pages/sysManage/video'),
                        meta: {
                            title: '视频备案',
                            icon: 'icon-Monitorpcvideocommunication'
                        },
                    },
                    {
                        path: 'singeUpgrade',
                        name: 'singeUpgrade',
                        fullPath: '/view/sysManage/singeUpgrade',
                        component: () => import('@/view/pages/sysManage/singeUpgrade'),
                        meta: {
                            title: '单个升级',
                            icon: 'icon-piliangshengji_huaban'
                        },
                    },
                    {
                        path: 'appupgradation',
                        name: 'appupgradation',
                        fullPath: '/view/sysManage/appupgradation',
                        component: () => import('@/view/pages/sysManage/appupgradation'),
                        meta: {
                            title: '六代机app更新',
                            icon: 'icon-piliangshengji_huaban'
                        },
                    },
                    {
                        path: 'applet',
                        name: 'applet',
                        fullPath: '/view/sysManage/applet',
                        component: () => import('@/view/pages/sysManage/applet'),
                        meta: {
                            title: '小程序账户',
                            icon: 'icon-piliangshengji_huaban'
                        },
                    },
                ]
            },
        ]
    }, {
        path: '/login',
        name: 'Index',
        // 路由懒加载，即进入该路由才会加载该路由，不会一进入网页就初始化所有路由，会造成客户端响应延迟
        component: () => import('@/view/login/index'),
        // hidden 代表该条路由不在菜单展示，一般为登录页，403,404页
        hidden: true
    }, {
        path: '/projectScreen',
        name: 'projectScreen',
        component: () => import('@/view/pages/screen/index'),
      
    }, 
    {
        path: '/screenProject',
        name: 'screenProject',
        component: () => import('@/view/pages/screen/screenProject'),
      
    }, 
    {
        path: '/screnDetail',
        name: 'screnDetail',
        component: () => import('@/view/pages/screen/screnDetail'),
      
    },
    {
        path: '/fiveScren',
        name: 'fiveScren',
        component: () => import('@/view/pages/screen/fiveScren'),
      
    },
    {
        path: '/FugitiveScren',
        name: 'FugitiveScren',
        component: () => import('@/view/pages/screen/FugitiveScren'),
      
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/view/error/page_403')
    }, {
        path: '*',
        name: '404',
        component: () => import('@/view/error/page_404')
    }
]
export default constantRoutes